// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-jsx": () => import("./../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-books-jsx": () => import("./../src/pages/books.jsx" /* webpackChunkName: "component---src-pages-books-jsx" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-jsx": () => import("./../src/pages/order.jsx" /* webpackChunkName: "component---src-pages-order-jsx" */)
}

